import { GET_WINES, SELECT_WINES } from '../constants/action-types';

export const INITIAL_STATE = {
    isLoading: false,
    selectedWineKeys: [672070, 672068, 672064, 672065],
    wines: [],
};

const wine = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SELECT_WINES:
            return {
                ...state,
                selectedWineKeys: action.productKeys,
            };

        case GET_WINES.request:
            return {
                ...state,
                isLoading: true,
            };

        case GET_WINES.failure:
            return {
                ...state,
                isLoading: false,
            };

        case GET_WINES.success:
            return {
                ...state,
                isLoading: false,
                wines: action.data,
            };

        default:
            return state;
    }
};

export default wine;
