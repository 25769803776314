export const GROUP_BY_OPTIONS = {
    age: 'age',
    all: 'all',
    gender: 'gender',
    generation: 'generation',
    product: 'product',
    varietal: 'varietal',
};

export const INCLUDE_OPTIONS = {
    all: 'all',
    allAverages: 'all_averages',
    averageRating: 'average_rating',
    negative: 'negative',
    positive: 'positive',
    varietal: 'varietal',
    varietals: 'varietals',
};

export const MAP_GRANULARITY_OPTIONS = {
    stateAbbreviated: 'state_abbreviated',
};

export const ORDER_OPTIONS = {
    ascending: 'ascending',
    descending: 'descending',
};

export const NEGATIVE_RATINGS = [1, 2];
export const POSITIVE_RATINGS = [4, 5];
