import { LOGIN, SET_ACCESS_TOKEN } from '../constants/action-types';

export const INITIAL_STATE = {
    isLoading: false,
    isLoggedIn: false,
    tokens: {},
};

const auth = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN.request:
            return {
                ...state,
                isLoading: true,
                isLoggedIn: false,
            };

        case LOGIN.failure:
            return {
                ...state,
                isLoading: false,
            };

        case LOGIN.success:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: true,
                tokens: {
                    accessToken: action.access_token,
                    expiresAt: action.expires_at,
                    refreshToken: action.refresh_token,
                },
            };

        case SET_ACCESS_TOKEN:
            return {
                ...state,
                tokens: {
                    ...state.tokens,
                    accessToken: action.access_token,
                    expiresAt: action.expires_at,
                },
            };

        default:
            return state;
    }
};

export default auth;
