import { GET_WINE_RATINGS_AGGREGATED } from '../constants/action-types';

export const INITIAL_STATE = {
    isLoading: false,
    allAverages: [],
    varietals: [],
    wines: [],
};

const wineRatingsAggregated = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_WINE_RATINGS_AGGREGATED.request:
            return {
                ...state,
                isLoading: true,
            };

        case GET_WINE_RATINGS_AGGREGATED.failure:
            return {
                ...state,
                isLoading: false,
            };

        case GET_WINE_RATINGS_AGGREGATED.success:
            return {
                ...state,
                isLoading: false,
                allAverages: action.data.all_averages || [],
                varietals: action.data.varietals || [],
                wines: action.data.wines,
            };

        default:
            return state;
    }
};

export default wineRatingsAggregated;
