import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
} from 'react-router-dom';
import ErrorBoundary from './lazy-loading/ErrorBoundary';
import Loader from './shared/Loader';

/* istanbul ignore next */
const AppContainer = React.lazy(() => import('./layout/AppContainer'));

const App = () => (
    <Router>
        <Switch>
            <Route path="/">
                <ErrorBoundary>
                    <React.Suspense fallback={<Loader />}>
                        <AppContainer />
                    </React.Suspense>
                </ErrorBoundary>
            </Route>
        </Switch>
    </Router>
);

export default App;
