import React from 'react';
import PropTypes from 'prop-types';
import LoaderImage from '../../assets/images/loader.gif';
import styles from './Loader.scss';

const Loader = ({ className }) => (
    <div className={`${styles.container} ${className}`}>
        <img alt="loading indicator" src={LoaderImage} />
    </div>
);

Loader.propTypes = {
    className: PropTypes.string,
};

Loader.defaultProps = {
    className: '',
};

export default Loader;
