import { applyMiddleware, compose, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import clientMiddlware from '../middleware/client-middleware';
import rootReducer from '../reducers';

const persistConfig = {
    key: 'root',
    storage,
};

const configureStore = (preloadedState) => {
    const persistedReducer = persistReducer(persistConfig, rootReducer);

    const middlewares = [clientMiddlware, thunkMiddleware];
    if (process.env.NODE_ENV === 'development') {
        const { logger } = require('redux-logger'); // eslint-disable-line global-require
        middlewares.push(logger);
    }

    const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
    const store = createStore(persistedReducer, preloadedState, composeEnhancers(applyMiddleware(...middlewares)));
    const persistor = persistStore(store);

    if (process.env.NODE_ENV !== 'production' && module.hot) {
        module.hot.accept('../reducers', () => {
            const nextRootReducer = require('../reducers').default; // eslint-disable-line global-require
            store.replaceReducer(persistReducer(persistConfig, nextRootReducer));
        });
    }

    return { persistor, store };
};

export default configureStore;
