import { GET_WINE_REVIEWS } from '../constants/action-types';

export const INITIAL_STATE = {
    isLoading: false,
    data: [],
};

const wineReviews = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_WINE_REVIEWS.request:
            return {
                ...state,
                isLoading: true,
            };

        case GET_WINE_REVIEWS.failure:
            return {
                ...state,
                isLoading: false,
            };

        case GET_WINE_REVIEWS.success:
            return {
                ...state,
                isLoading: false,
                data: action.data,
            };

        default:
            return state;
    }
};

export default wineReviews;
