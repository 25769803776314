const handleResponse = async (response) => {
    const json = await response.json();
    if (response.ok) {
        return json;
    }

    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
        statusCode: response.status,
        ...json,
    });
};

const makeFetch = async (endpoint, queryParams = {}, options = {}) => {
    const url = new URL(`${process.env.API_BASE_URL}${endpoint}`);
    Object.keys(queryParams).forEach((currentKey) => {
        url.searchParams.append(currentKey, queryParams[currentKey]);
    });

    const fetchResponse = await fetch(url, options);
    return handleResponse(fetchResponse);
};

const getAsync = async (endpoint, queryParams) => (
    makeFetch(endpoint, queryParams)
);

const getAuthenticatedAsync = async (accessToken, endpoint, queryParams) => (
    makeFetch(endpoint, queryParams, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    })
);

const postAsync = async (endpoint, queryParams, body = {}) => (
    makeFetch(endpoint, queryParams, {
        body: JSON.stringify(body),
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
    })
);

export default {
    getAsync,
    getAuthenticatedAsync,
    makeFetch,
    postAsync,
};
