import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import auth from './auth';
import wine from './wine';
import wineConversions from './wine-conversions';
import wineDetails from './wine-details';
import wineRatingsAggregated from './wine-ratings-aggregated';
import createWineRatingsAggregatedMapReducer from './wine-ratings-aggregated-map';
import createWineRatingsAggregatedGroupsReducer from './wine-ratings-aggregated-groups';
import wineRatingsAggregatedMapRegions from './wine-ratings-aggregated-map-regions';
import wineRatingsDistributions from './wine-ratings-distributions';
import wineRatingsStatsAge from './wine-ratings-stats-age';
import wineReviews from './wine-reviews';
import wineSimilarities from './wine-similarities';
import { LOGOUT } from '../constants/action-types';
import { GROUP_BY_OPTIONS, MAP_GRANULARITY_OPTIONS } from '../constants/api';

const appReducer = combineReducers({
    auth,
    wine,
    wineConversions,
    wineDetails,
    wineRatingsAggregated,
    wineRatingsAggregatedGroupsAge: createWineRatingsAggregatedGroupsReducer(GROUP_BY_OPTIONS.age),
    wineRatingsAggregatedGroupsGender: createWineRatingsAggregatedGroupsReducer(GROUP_BY_OPTIONS.gender),
    wineRatingsAggregatedGroupsGeneration: createWineRatingsAggregatedGroupsReducer(GROUP_BY_OPTIONS.generation),
    wineRatingsAggregatedMapRegions,
    wineRatingsAggregatedMapState: createWineRatingsAggregatedMapReducer(MAP_GRANULARITY_OPTIONS.stateAbbreviated),
    wineRatingsDistributions,
    wineRatingsStatsAge,
    wineReviews,
    wineSimilarities,
});

const rootReducer = (state, action) => {
    let nextState = { ...state };
    if (action.type === LOGOUT) {
        storage.removeItem('persist:root');
        nextState = undefined;
    }

    return appReducer(nextState, action);
};

export default rootReducer;
