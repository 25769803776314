import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './components/App';
import configureStore from './store/configure-store';
import Loader from './components/shared/Loader';
import './register-pdf-files';

const { persistor, store } = configureStore();

const renderApp = () => {
    ReactDOM.render(
        <Provider store={store}>
            <PersistGate loading={<Loader />} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>,
        document.getElementById('app')
    );
};

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./components/App', renderApp);
}

renderApp();
