import { GET_WINE_RATINGS_AGGREGATED_MAP_REGIONS } from '../constants/action-types';

export const INITIAL_STATE = {
    isLoading: false,
    data: [],
};

const wineRatingsAggregatedMapRegions = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_WINE_RATINGS_AGGREGATED_MAP_REGIONS.request:
            return {
                ...state,
                isLoading: true,
            };

        case GET_WINE_RATINGS_AGGREGATED_MAP_REGIONS.failure:
            return {
                ...state,
                isLoading: false,
            };

        case GET_WINE_RATINGS_AGGREGATED_MAP_REGIONS.success:
            return {
                ...state,
                isLoading: false,
                data: action.data,
            };

        default:
            return state;
    }
};

export default wineRatingsAggregatedMapRegions;
