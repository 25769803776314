import { GET_WINE_CONVERSIONS } from '../constants/action-types';

export const INITIAL_STATE = {
    allConversions: [],
    isLoading: false,
    varietals: [],
    wines: [],
};

const wineConversions = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_WINE_CONVERSIONS.request:
            return {
                ...state,
                isLoading: true,
            };

        case GET_WINE_CONVERSIONS.failure:
            return {
                ...state,
                isLoading: false,
            };

        case GET_WINE_CONVERSIONS.success:
            return {
                ...state,
                isLoading: false,
                allConversions: action.data.all_conversion_rates,
                varietals: action.data.varietal_conversion_rates,
                wines: action.data.wines,
            };

        default:
            return state;
    }
};

export default wineConversions;
