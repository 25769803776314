import moment from 'moment-timezone';
import { LOGOUT, SET_ACCESS_TOKEN } from '../constants/action-types';
import api from '../actions/utils/api';

// five minutes
export const FRESH_SECONDS = 5 * 60;

const clientMiddleware = ({ dispatch, getState }) => (next) => async (action) => {
    if (typeof action === 'function') {
        return action(dispatch, getState);
    }

    const { request, type } = action;
    if (!request) {
        return next(action);
    }

    const extraData = action.extra || {};
    next({ type: type.request, ...extraData });

    const { tokens } = getState().auth;
    const refreshThreshold = moment().unix() + FRESH_SECONDS;

    let validAccessToken = tokens.accessToken;
    if (tokens.refreshToken && refreshThreshold > tokens.expiresAt) {
        try {
            const refreshResult = await api.makeFetch('/auth/refresh/', {}, {
                body: JSON.stringify({}),
                headers: {
                    Authorization: `Bearer ${tokens.refreshToken}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });

            dispatch({ type: SET_ACCESS_TOKEN, ...refreshResult });
            validAccessToken = refreshResult.access_token;
        } catch (err) {
            next({ type: type.failure, ...extraData, ...err });
            return dispatch({ type: LOGOUT });
        }
    }

    try {
        const requestResult = await request(validAccessToken);
        return next({ type: type.success, ...extraData, ...requestResult });
    } catch (err) {
        return next({ type: type.failure, ...extraData, ...err });
    }
};

export default clientMiddleware;
