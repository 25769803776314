export const buildActionNames = (baseName) => ({
    failure: `${baseName}_FAILURE`,
    request: `${baseName}_REQUEST`,
    success: `${baseName}_SUCCESS`,
});

// auth
export const LOGIN = buildActionNames('LOGIN');
export const LOGOUT = 'LOGOUT';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';

// wine-conversions
export const GET_WINE_CONVERSIONS = buildActionNames('GET_WINE_CONVERSIONS');

// wine-details
export const GET_WINE_DETAILS = buildActionNames('GET_WINE_DETAILS');

// wine-ratings-aggregated
export const GET_WINE_RATINGS_AGGREGATED = buildActionNames('GET_WINE_RATINGS_AGGREGATED');

// wine-ratings-aggregated-groups
export const GET_WINE_RATINGS_AGGREGATED_GROUPS = buildActionNames('GET_WINE_RATINGS_AGGREGATED_GROUPS');

// wine-ratings-aggregated-map
export const GET_WINE_RATINGS_AGGREGATED_MAP = buildActionNames('GET_WINE_RATINGS_AGGREGATED_MAP');

// wine-ratings-aggregated-map-regions
export const GET_WINE_RATINGS_AGGREGATED_MAP_REGIONS = buildActionNames('GET_WINE_RATINGS_AGGREGATED_MAP_REGIONS');

// wine-ratings-stats-age
export const GET_WINE_RATINGS_STATS_AGE = buildActionNames('GET_WINE_RATINGS_STATS_AGE');

// wine-ratings-distributions
export const GET_WINE_RATINGS_DISTRIBUTIONS = buildActionNames('GET_WINE_RATINGS_DISTRIBUTIONS');

// wine-reviews
export const GET_WINE_REVIEWS = buildActionNames('GET_WINE_REVIEWS');

// wine-similarities
export const GET_WINE_SIMILARITIES = buildActionNames('GET_WINE_SIMILARITIES');

// wine
export const GET_WINES = buildActionNames('GET_WINES');
export const SELECT_WINES = 'SELECT_WINES';
