import { GET_WINE_RATINGS_AGGREGATED_GROUPS } from '../constants/action-types';

export const INITIAL_STATE = {
    isLoading: false,
    data: [],
};

const wineRatingsAggregatedGroups = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_WINE_RATINGS_AGGREGATED_GROUPS.request:
            return {
                ...state,
                isLoading: true,
            };

        case GET_WINE_RATINGS_AGGREGATED_GROUPS.failure:
            return {
                ...state,
                isLoading: false,
            };

        case GET_WINE_RATINGS_AGGREGATED_GROUPS.success:
            return {
                ...state,
                isLoading: false,
                data: action.data,
            };

        default:
            return state;
    }
};

const createWineRatingsAggregatedGroupsReducer = (groupByName) => (state, action) => {
    const isInitializationCall = state === undefined;
    if (action.groupBy !== groupByName && !isInitializationCall) {
        return state;
    }

    return wineRatingsAggregatedGroups(state, action);
};

export default createWineRatingsAggregatedGroupsReducer;
