import { GET_WINE_RATINGS_AGGREGATED_MAP } from '../constants/action-types';

export const INITIAL_STATE = {
    isLoading: false,
    data: [],
};

const wineRatingsAggregatedMap = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_WINE_RATINGS_AGGREGATED_MAP.request:
            return {
                ...state,
                isLoading: true,
            };

        case GET_WINE_RATINGS_AGGREGATED_MAP.failure:
            return {
                ...state,
                isLoading: false,
            };

        case GET_WINE_RATINGS_AGGREGATED_MAP.success:
            return {
                ...state,
                isLoading: false,
                data: action.data,
            };

        default:
            return state;
    }
};

const createWineRatingsAggregatedMapReducer = (granularityName) => (state, action) => {
    const isInitializationCall = state === undefined;
    if (action.granularity !== granularityName && !isInitializationCall) {
        return state;
    }

    return wineRatingsAggregatedMap(state, action);
};

export default createWineRatingsAggregatedMapReducer;
